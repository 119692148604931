import dynamic from 'next/dynamic';
import { setCookie } from 'cookies-next';

const Feed = dynamic(() => import('../components/feed/feed.component'), {
  ssr: false,
});
const Header = dynamic(() => import('../components/header/header.component'), {
  ssr: false,
});

import { getArticles } from './api/v1/articles/getArticles';

import { categories } from '@/data/categories.data';

import { MainWrapper } from '@/styles/global.elements';

export const getServerSideProps = async ({ res, req, params }) => {
  const { category: paramCategory, portal } = params;
  const { categories: filterCategories, feeds: filterFeeds, userLoggedIn } = req.cookies;

  const getCategories = () => {
    // Ako postoji kategorija u URL, prikazi nju prvo
    if (paramCategory && categories.filter((category) => category.slug === paramCategory[0])[0]) {
      return [categories.filter((category) => category.slug === paramCategory[0])[0].name];
    }
    // Ako nema kategorije u URL ('/') niti usera, postavi sve kategorije
    else if (!paramCategory && !userLoggedIn) {
      return categories.map((category) => category.name);
    }
    // Ako postoji cookie s kategorijama, prikazi te kategorije
    else if (filterCategories && JSON.parse(filterCategories).length > 0) {
      return categories
        .filter((category) => JSON.parse(filterCategories).includes(category.name))
        .map((category) => category.name);
    }
    // Ako nista drugo, prikazi sve kategorije
    return categories.map((category) => category.name);
  };

  setCookie('categories', getCategories(), { req, res, maxAge: 60 * 6 * 24 });

  const articles = await getArticles({
    categories: getCategories(),
    feeds: filterFeeds ? JSON.parse(filterFeeds) : null,
    portal,
    start: 0,
    count: process.env.ARTICLES_COUNT,
  });

  return {
    props: { categories: getCategories(), articles, userLoggedIn: userLoggedIn ? true : false },
  };
};

const HomePage = ({ categories, articles, userLoggedIn }) => (
  <MainWrapper>
    <Header userLoggedIn={userLoggedIn} />
    <Feed categories={categories} articles={articles} userLoggedIn={userLoggedIn} />
  </MainWrapper>
);

export default HomePage;
